define("semente-web-app/models/comp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    tableOrder: _emberData.default.attr(),
    sigla: _emberData.default.attr(),
    descricao: _emberData.default.attr(),
    dominio: _emberData.default.belongsTo('dominio', {
      async: true
    }),
    quizes: _emberData.default.hasMany('quiz-comp', {
      async: true
    }),
    normativas: _emberData.default.hasMany('base-normativa', {
      async: true
    }),
    historicoComps: _emberData.default.hasMany('historico-comp', {
      async: true
    }),
    limites: _emberData.default.hasMany('limite-faixa', {
      async: true
    }),
    orientacoes: _emberData.default.attr()
  });
  _exports.default = _default;
});