define("semente-web-app/models/dominio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    tableOrder: _emberData.default.attr(),
    color: Ember.computed('name', function () {
      if (this.get('idx') == 1) return 'autogestao';
      if (this.get('idx') == 2) return 'engajamento';
      if (this.get('idx') == 3) return 'amabilidade';
      if (this.get('idx') == 4) return 'modulacao';
      if (this.get('idx') == 5) return 'abertura';
    }),
    icon: Ember.computed('name', function () {
      if (this.get('idx') == 1) return 'management';
      if (this.get('idx') == 2) return 'engagement';
      if (this.get('idx') == 3) return 'kindness';
      if (this.get('idx') == 4) return 'resilience';
      if (this.get('idx') == 5) return 'openness';
    }),
    comps: _emberData.default.hasMany('comp', {
      async: true
    }),
    limites: _emberData.default.hasMany('limite-faixa-dominio', {
      async: true
    })
  });
  _exports.default = _default;
});