define("semente-web-app/models/atividade", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    description: _emberData.default.attr(),
    timesection: _emberData.default.attr(),
    dia: _emberData.default.attr(),
    abertura: _emberData.default.attr(),
    atividade: _emberData.default.attr(),
    videoId: _emberData.default.attr(),
    video: _emberData.default.attr(),
    quiz: _emberData.default.attr(),
    quizdata: _emberData.default.attr(),
    teoria: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    secondCoverImage: _emberData.default.attr(),
    bloco: _emberData.default.belongsTo('bloco', {
      async: true
    }),
    comps: _emberData.default.hasMany('comp', {
      async: true
    }),
    instrutor: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    secoes: _emberData.default.hasMany('secao', {
      async: true
    }),
    questionarios: _emberData.default.hasMany('aplicacao-questionario', {
      async: true
    }),
    aplicacaoAulas: _emberData.default.hasMany('aplicacao-aula', {
      async: true
    }),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    acompanhamentosAtividadeInstituicao: _emberData.default.hasMany('acompanhamento-atividade-instituicao', {
      async: true
    }),
    backgroundImage: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    }),
    secondBackgroundImage: Ember.computed('secondCoverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('secondCoverImage') + "');");
    }),
    color: Ember.computed('name', function () {
      if (this.get('idx') == 1) return 'autogestao';
      if (this.get('idx') == 2) return 'abertura';
      if (this.get('idx') == 3) return 'modulacao';
      if (this.get('idx') == 4) return 'engajamento';
      if (this.get('idx') == 5) return 'amabilidade';
    })
  });
  _exports.default = _default;
});